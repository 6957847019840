import React from "react"
import Img from "gatsby-image"
import TransitionLink from "gatsby-plugin-transition-link";

const ProductCard = ({ product, exitTransition, entryTransition }) => {

    return (
            <div className="single-mineral" >
                <Img className="main-img"
                     fluid={product.node.image.childImageSharp.fluid}
                     alt={product.node.name}
                />
                <span className="mineral-id">{product.node.name}</span>
                <TransitionLink
                    className="collection-minerals-details"
                    to={`/minerals/${product.node.mineral_collection_type.slug}/${product.node.slug}`}
                    exit={exitTransition}
                    entry={entryTransition}
                >
                    <p className="mineral-name">{product.node.name}</p>
                    <ul className="details-content">
                        <li>{product.node.country}</li>
                        <li>Locality</li>
                        <li>{product.node.size}</li>
                        <li>Size</li>
                        <li>{product.node.collection_ID}</li>
                        <li>Collection</li>
                    </ul>
                    <div className="btn-cta">Read more</div>
                </TransitionLink>
            </div>
    )
};

export default ProductCard
