import React, {useEffect, useRef, createRef} from "react"
import ProductCard from "./productCard"
import { gsap } from "gsap";

const Products = ({ products, exitTransition, entryTransition }) => {

    useEffect(() => {

        const cards = productCard.current.map(card => card.current);
        gsap.from([cards], 0.4, {
            opacity: 0,
            y:120,
            stagger:  .15,
            delay:0.2
        });


    }, []);

    const productCard = useRef(products.map(() => createRef()));

    return (
            <div className="collection-minerals">
                {products.map((product, i) => {
                    return (
                        <div key={`product__${product.node.slug}`} ref={productCard.current[i]}>
                            <ProductCard className="card" product={product} exitTransition={exitTransition} entryTransition={entryTransition} />
                        </div>
                    )
                })}
            </div>
    )
}

export default Products
