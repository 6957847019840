import React, {useEffect, useRef} from "react"
import { graphql } from "gatsby"

import Index from "../components/layout"
import Products from "../components/products/products"
import Tooltip from "../components/paginatorTooltip";
import TransitionLink from "gatsby-plugin-transition-link";
import scrollTo from "gatsby-plugin-smoothscroll";

import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const query = graphql`
  query mineral_collection_type($slug: String!) {
    products: allStrapiProducts(filter: {active: {eq: true}, mineral_collection_type: { slug: { eq: $slug }}}, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          name
          slug
          size
          collection_ID
          country
          locality
          mineral_collection_type {
            name
            slug
          }
          image {
            childImageSharp {
                fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
          }
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
      page_title
      description
      icon {
              publicURL
            }
    }
  }
`

const Category = ({ data, pageContext }) => {

  let categoryTitle  = useRef(null);
  let categorySubTitle  = useRef(null);

  useEffect(() => {
    TweenLite.from(categoryTitle , .4, {
      opacity: 0,
      ease: Power2.easeOut,
    });

    TweenLite.from(categorySubTitle , .4, {
      opacity: 0,
      ease: Power2.easeOut,
    });

  }, [categoryTitle, categorySubTitle]);

  const products = data.products.edges;
  const category = data.category;
  const {prev, next} = pageContext;


  const TRANSITION_LENGTH = .5

  const exitTransition = {
    length: TRANSITION_LENGTH,
    trigger: () =>  animateExitPage(),
  }

  const exitReverseTransition = {
    length: TRANSITION_LENGTH,
    trigger: () =>  animateExitPageReverse(),
  }

  const entryTransition = {
    delay: TRANSITION_LENGTH,
    trigger: () => animateEntryPage(),
  }

  function animateExitPage() {

    let html = document.querySelector("html");
    let mainLayout = document.querySelector('.main');
    let footer = document.querySelector('.footer');

    html.classList.add("smoothScrollOff")
    mainLayout.classList.add("invisible")

    TweenLite.to(
        mainLayout,
        .8,
        {
          opacity: 0,
          ease: Power2.easeInOut,
        }
    );

    TweenLite.to(
        footer,
        .4,
        {
          opacity: 0,
          ease: Power2.easeInOut,
        }
    );

    setTimeout(() => {
      scrollTo('#home')
    }, 450);
  }

  function animateExitPageReverse() {

    let html = document.querySelector("html");
    let mainLayout = document.querySelector('.main');
    let footer = document.querySelector('.footer');

    html.classList.add("smoothScrollOff")
    mainLayout.classList.add("invisible")

    TweenLite.to(
        mainLayout,
        .8,
        {
          opacity: 0,
          x: '-60%',
          ease: Power2.easeInOut,
        }
    );

    TweenLite.to(
        footer,
        .4,
        {
          opacity: 0,
          ease: Power2.easeInOut,
        }
    );

    setTimeout(() => {
      scrollTo('#home')
    }, 450);
  }

  function animateEntryPage() {

    let html = document.querySelector("html");

    setTimeout(() => {
      html.classList.remove("smoothScrollOff")
    }, 120);
  }

  return (
    <Index
      title={category.name}
      description={category.description}
    >
      <section className="regular-section collection-wrapper">
        <div className="collection-header">
          <h1 ref={el => (categoryTitle = el)} >{category.name}</h1>
          <p ref={el => (categorySubTitle = el)} >{category.description}</p>
        </div>
        <Products products={products} exitTransition={exitTransition} entryTransition={entryTransition}/>
      </section>
      <section className="regular-section more-collections">
        <h2>More Collections</h2>
        <div className="paginator-big">

          {prev &&
          <Tooltip text={ prev.name}>
            <TransitionLink
                className="read-more"
                to={"/minerals/" + prev.slug}
                exit={exitTransition}
                entry={entryTransition}
            >
              <i className="icons icon-west"/>
              <span className="collection-number">Prev Collection</span>
            </TransitionLink>
          </Tooltip>
          }

          {next &&
          <Tooltip text={ next.name}>
            <TransitionLink
                className="read-more"
                to={"/minerals/" + next.slug}
                exit={exitReverseTransition}
                entry={entryTransition}
            >
              <span className="collection-number">Next Collection</span>
              <i className="icons icon-east"/>
            </TransitionLink>
          </Tooltip>
          }

        </div>
      </section>
    </Index>
  )
}

export default Category
